<template>
  <container-list-extend class="d-flex flex-column w-100" @toggle="show = !show">
    <template v-slot:title>
      <div class="d-flex flex-row flex-grow-1 p-2 align-items-center">
        <BaseIcon v-if="doc.type_id == 1" name="folder" color="#ffc107" width="25" height="25"></BaseIcon>
        <BaseIcon v-if="doc.type_id == 2" name="file" color="#007bff" width="25" height="25" class></BaseIcon>
        <BaseIcon v-if="doc.type_id == 3" name="folder" color="#6f42c1" width="25" height="25" class></BaseIcon>
        <div class="ml-2 d-flex flex-row flex-grow-1">
          <span class="mr-2">
            <strong>{{ doc.identifiant }}</strong>
          </span>
          <span>{{ doc.nom }}</span>
          <span v-if="doc.news == 1" class="d-flex flex-row flex-grow-1">
            <BaseIcon name="alert-triangle" color="#007bff" width="10" height="10" class="ml-4 mt-1 text-danger"></BaseIcon> <small class="mt-1 text-danger"> Nouvelle version</small>
          </span>
          <div class="text-muted" v-if="show">
            <small>{{ doc.description }}</small>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:form>
      <div class="p-3">
        <documents-viewer
          v-if="doc.type_id === 1"
          :id="'document_pdf' + doc.id"
          file_system="DOCS"
          file_field="pdf"
          :file_key="doc.id"
        ></documents-viewer>
        <documents-viewer
          v-if="doc.type_id === 2 || doc.type_id === 3 || doc.type_id === 4"
          :id="'document_word' + doc.id"
          file_system="DOCS"
          file_field="word"
          :file_key="doc.id"
        ></documents-viewer>
      </div>
    </template>
  </container-list-extend>
</template>
<script>
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import DocumentsViewer from "@/components/profil/DocumentsItemViewer.vue";
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  name: "Profil",
  props: ["doc"],
  components: {
    ContainerListExtend,
    DocumentsViewer,
    BaseIcon,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {},
  methods: {},
};
</script>
<style></style>
