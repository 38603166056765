<template>
  <ContainerPage>
    <div class="w-100">
      <div class="d-flex flex-column flex-lg-row align-items-center p-3 w-100">
        <AppAvatar :url="avatar" propSize="6rem" ref="avatar"></AppAvatar>
        <div class="ml-lg-3 mt-3 mt-lg-0 text-center text-lg-left">
          <h2>{{ name }}</h2>
          <h4 class="text-muted">{{ email }}</h4>
        </div>
      </div>
      <div class="pl-3 pr-3 d-flex flex-column flex-lg-row justify-content-between">
        <formations></formations>
        <declarations></declarations>
      </div>
      <div class="pl-3 pr-3 d-flex flex-column flex-lg-row justify-content-between">
        <Accueil></Accueil>
      </div>
      <div class="mb-3">
        <documents></documents>
      </div>
    </div>
  </ContainerPage>
</template>
<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import ContainerPage from "../components/containers/ContainerPage.vue";
//import BaseForm from "@/components/bases/Form.vue";
import Documents from "@/components/profil/Documents.vue";
import Formations from "@/components/profil/Formations.vue";
import Declarations from "@/components/profil/Declarations.vue";
import AppAvatar from "@/components/bases/Avatar.vue";
import Accueil from "@/components/profil/Accueil.vue";

export default {
  name: "Profil",
  components: {
    ContainerPage,
    Documents,
    Formations,
    Declarations,
    AppAvatar,
    Accueil,
  },
  data() {
    return {
      loading: false,
      feedback: {},
      lists: {},
      documents: [],
    };
  },
  computed: {
    ...mapFields("user", ["id", "name", "email", "avatar"]),
    ...mapFields("collaborateurs", ["collaborateur"]),
  },
  methods: {
    ...mapActions({
      getCollaborateur: "collaborateurs/getCollaborateur",
      getData: "collaborateurs/getData",
    }),
  },
  mounted() {
    //this.getCollaborateur(this.id);
  },
};
</script>
<style></style>
