<template>
  <div class="m-auto">
    <div class="d-flex flex-column flex-lg-row align-items-center">
      <div class="w-50 mb-5">
        <img v-if="image" class="rounded mx-auto d-block mt-3 mb-3" style="width: 90%" v-bind:src="imageUrl" />
      </div>
      <div class="w-100">
        <div class="pl-3 pr-3 pl-lg-5 pr-lg-5" v-html="text"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["image", "text"],
  computed: {
    imageUrl: function () {
      return process.env.VUE_APP_API_URL + "/storage/" + this.image;
    },
  },
  methods: {},
  mounted() {},
};
</script>
<style></style>
