<template>
  <div class="w-100 p-3 rounded mb-3" v-background-3>
    <h5>Déclarations</h5>
    <div>
      Module en cours de fabrication...
      <!--
      <div class="text-primary">Mes déclarations</div>
      <div class="text-primary">Déclarer un accident</div>
      <div class="text-primary">Déclarer une situation dangereuse</div>
      <div class="text-primary">Déclarer un écart (Non-conformité)</div>-->
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      feedback: {},
    };
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
<style></style>
