<template>
  <div class="m-auto">
    <div class="mb-5 mt-5">
      <img v-if="image" class="rounded mx-auto d-block" style="width: 100%; max-width: 800px" v-bind:src="imageUrl" />
    </div>
    <div class="" style="max-width: 900px">
      <div class="pl-3 pr-3 pl-lg-5 pr-lg-5" v-html="text"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["image", "text"],
  computed: {
    imageUrl: function () {
      return process.env.VUE_APP_API_URL + "/storage/" + this.image;
    },
  },
  methods: {},
  mounted() {},
};
</script>
<style></style>
