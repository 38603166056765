<template>
  <div
    class="d-flex justify-content-between align-items-center p-3 fixed-bottom"
    v-background-2
    style="height: 80px; z-index: 9002"
  >
    <div>{{ description }}</div>
    <div class="d-flex align-items-center">
      <button-circle v-if="showPrev" name="arrow-left" size="24" @click="prev"></button-circle>
      <div class="ml-1 mr-1">{{ counter }}</div>
      <button-circle name="arrow-right" size="24" @click="next" v-if="showNext"> </button-circle>
      <button-circle class="ml-3" name="x" size="24" @click="stop" v-if="showClose"></button-circle>
      <button class="btn btn-primary btn-sm ml-3" size="18" @click="end" v-if="showEnd">Terminer</button>
    </div>
  </div>
</template>
<script>
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  components: { ButtonCircle },
  data() {
    return {};
  },
  props: ["questions", "index", "description", "total"],
  methods: {
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    },
    stop() {
      this.$emit("stop");
    },
    end() {
      this.$emit("end");
    },
  },
  computed: {
    counter: function () {
      return this.index + 1 + "/" + this.total;
    },
    showPrev: function () {
      return !this.questions && this.index > 0;
    },
    showNext: function () {
      return !this.questions && this.index < this.total - 1;
    },
    showClose: function () {
      return !this.questions && this.index < this.total - 1;
    },
    showEnd: function () {
      return !this.questions && this.index == this.total - 1;
    },
  },
};
</script>
<style></style>
